import react, {useEffect, useState} from 'react'
import {renderToString} from 'react-dom/server';
import React from "react";
import {AlertIcon, CopyIcon, Spinner, SubscriptionErrorIcon} from "../../../elements/icons";
import {BuildSegments, buildSentenceTooltipStyle} from "../../../segments/segments";
import {britishizeRequest} from "../../../requests";
import {ProgressBar} from "../../../elements/progressBar";
import styled, {css} from "styled-components";
import {CopyToClipboard} from "react-copy-to-clipboard"
import {colorize_diffs} from "../../../elements/utils/utils";

// import Diff from "text-diff";
// import diff from 'simple-text-diff'
const Diff = require('diff');
const levenshtein = require('js-levenshtein');

const TEXT_SYM_LIMIT = 10000;

const PaddedDiv = styled.div`
  padding-left: ${p => p.x};
  padding-right: ${p => p.x};
  width: ${p => `calc(100% - 2 * ${p.x})`};
`

const WideDiv = styled.div`
  width: 100%
`

const Box = styled.div`
  margin: 4px;
  padding: 12px 20px 12px 20px;
  background-color: #FFFFFF;
  border-radius: 16px;
  border: solid 1px #EDF1F9;
`

const Line = styled.div`
  display: flex;
  justify-content: center;
`

function getErrorIcon(errorText) {
    if (errorText === 'Try typing more text (>250 characters) so we can give you more accurate results') {
        return <AlertIcon/>;
    } else {
        return <SubscriptionErrorIcon/>
    }
}


const DisplayError = (props) => {
    return <div
        className="alert__container"
        style={{
            backgroundColor: props.error.background || 'rgba(254, 247, 209, 1)',
            color: `rgba(117, 81, 24, 1)`,
            borderColor: props.error.border || 'rgba(247, 199, 82, 1)'
        }}
    >
        {
            getErrorIcon(props.error)
        }
        <span
            className='alert__text'
            style={{
                color: `rgba(117, 81, 24, 1)`
            }}
        >
            {props.error.text}
        </span>
    </div>
}


const TextareaBlock = (props) => {
    const {error, text, setText, setFile, html, style, children, bgColor, editable = true, className} = props;

    const style_ = (error ? {
        borderColor: `rgba(247, 199, 82, 1)`
    } : {}) || {}
    return <Box
        className={"textarea " + (error ? 'textarea__alert-parent' : '')}
        style={{
            ...style_,
            width: "unset",
            backgroundColor: bgColor, ...style,
            display: "flex",
            flexDirection: "column"
        }}
    >
        {
            error ?
                <DisplayError error={error}/> : <></>
        }
        {
            html ?
                <div
                    contentEditable={true}
                    id="textarea"
                    style={{height: 'calc(100% - 60px)', overflow: 'scroll'}}
                    className={
                        ("editable-britishizer " +
                            (error?.length ?
                                'textarea__alert-container' : '')) + " " + className
                    }
                    onInput={(e) => {
                        setText(e.currentTarget.innerText)
                    }}
                    dangerouslySetInnerHTML={{__html: html}}
                ></div> :
                <textarea readOnly={!editable} style={{backgroundColor: bgColor, flexGrow: 1}} onChange={(e) => {
                    setText(e.target.value);
                }}
                          value={text}
                          id='textarea'
                          placeholder='Enter text to britishize (currently only English supported)'
                          autoFocus={true}
                ></textarea>
        }
        {children}
    </Box>
}


const TextareaControll = (props) => {
    const {text, setLoading, setPercents, setError, file, setResult, className, isChanged} = props;


    const sendDataToBackend = () => {
        setLoading(true);
        setPercents(-1);
        setError('');
        britishizeRequest(text).then((e) => {
            setLoading(false);

            if (e.error) {
                console.log("e.error", e.error)
                setError({text: e.error});
            } else {
                if (e.text.length > 0) {
                    setResult(e.text);
                } else {
                    console.log("no text in response", e)
                }
            }
            window.dispatchEvent(new Event("storage"));
        })
    }


    return <WideDiv style={{width: "unset", display: "flex"}}>
        <div className="words__count">
            <div className="words">
                {text.length} / {TEXT_SYM_LIMIT} characters
            </div>
            <ProgressBar
                progress={text.length / TEXT_SYM_LIMIT * 100}
            />
        </div>
        <div className="fd__btns" style={{marginLeft: "auto"}}>
            <button className='primary fd__button' onClick={() => {
                if (text.length === 0) {
                    setError({text: "No input text"})
                } else if (isChanged) {
                    sendDataToBackend();
                }
            }}>
                Britishize
            </button>
        </div>

    </WideDiv>
}

const ScanContainer = (props) => {
    const {loading, percents} = props;
    return <Box style={{display: "flex", gap: "16px"}}>
        <div className="scan-container__content">
            <div className="s-circle mixed"></div>
            <span>Original</span>
        </div>
        <div className="scan-container__content">
            <div className="s-circle human-written"></div>
            <span>Britishized</span>
        </div>
    </Box>
}

const InputArea = () => {
    const [file, setFile] = react.useState();

    const [text, setText] = react.useState("");
    const [sourceHtml, setSourceHtml] = react.useState(null);

    const [loading, setLoading] = useState(false);
    const [percents, setPercents] = react.useState(-1);
    const [error, setError] = useState(null);
    const [result, setResult] = useState('');
    const [resultText, setResultText] = useState('');
    const [isChanged, setChangedState] = useState(false);

    useEffect(() => {
        document.addEventListener('keydown', function (event) {
            const ta = document.getElementById('textarea')
            if (document.activeElement === ta) {
                return;
            }
            if ((event.metaKey && event.key === 'v') || (event.ctrlKey && event.key === 'v')) {
                navigator.clipboard.readText().then((e) => {
                    setText(text + e);
                });
            }
        });
        // if (!text.split('\n').join('').length) {
        //     setSegments('');
        // }
    }, [text, setText]);
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('text')) {
            setText(urlParams.get('text'))
        }
    }, []);


    return <WideDiv className='uploader__container'>
        <WideDiv>
            <WideDiv style={{display: "flex"}}>
                <TextareaBlock
                    className={"source"}
                    style={{flexBasis: "50%"}}
                    text={text}
                    setText={txt => {
                        if (txt.length > TEXT_SYM_LIMIT) {
                            setError({"text": "Too long text, only " + TEXT_SYM_LIMIT + " characters allowed"})
                        } else {
                            setError(null)
                        }
                        setChangedState(true)
                        setSourceHtml(null)
                        setResult(null)
                        setText(txt.slice(0, TEXT_SYM_LIMIT))
                    }}
                    setFile={setFile}
                    html={sourceHtml}
                    error={error}
                    segments={null}
                >
                    <TextareaControll
                        text={text}
                        isChanged={isChanged}
                        setError={setError}
                        setLoading={setLoading}
                        setPercents={setPercents}
                        setResult={changedText => {
                            setResultText(changedText)

                            const [newText, newChangedText] = colorize_diffs(text, changedText)
                            setChangedState(false)
                            setSourceHtml(newText)
                            setResult(newChangedText)
                        }}
                        file={file}
                    />
                </TextareaBlock>
                <TextareaBlock
                    className={"target"}
                    bgColor={"#EEF0F2"}
                    style={{flexBasis: "50%"}}
                    editable={false}
                    html={result}
                    setText={null}
                    setFile={null}
                    error={null}
                    segments={null}
                >

                    <Line style={{height: "58px"}}>
                        {loading && <div className="spin__container-b">
                            <div className="spin">
                                <Spinner/>
                            </div>
                            <div className="spin__container">
                                Processing...
                            </div>
                        </div>}

                        <CopyToClipboard text={resultText}>
                            <div style={{
                                display: "flex",
                                gap: 2.5,
                                alignItems: "center",
                                marginLeft: "auto",
                                marginRight: "0.5em",
                                cursor: "pointer"
                            }}>
                                <CopyIcon style={{marginLeft: "auto"}}/>
                                <div>Copy</div>
                            </div>
                        </CopyToClipboard>
                    </Line>

                </TextareaBlock>
            </WideDiv>

            <ScanContainer
                loading={loading}
                percents={percents}
            />
        </WideDiv>
    </WideDiv>
}

export const Britishizer = () => {
    return <div className='layout__container centered-layout' style={{flexGrow: "1", height: "100vh"}}>
        <PaddedDiv x={"20px"}>
            <span className="header">Britishize text</span>
            <WideDiv className="file__screen">
                <InputArea/>
            </WideDiv>
        </PaddedDiv>
    </div>
}