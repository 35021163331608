import react, { useEffect, useState } from 'react'
import { ProgressBar } from '../progressBar'
import './style.css'
import { getWordsUsed } from '../../requests';
import { useNavigate } from 'react-router-dom';
import { trackEvent } from '../../requests';

export const formatBigNumbers = (number: string) => {
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export const CurrentPlan: react.FC = () => {
    const [wordsUsedDeepScan, setWordsUsedDeepScan] = useState(0);
    const [wordsUsedSimpleScan, setWordsUsedSimpleScan] = useState(0);
    const [wordsGrantedDeepScan, setWordsGrantedDeepScan] = useState(0);
    const [wordsGrantedSimpleScan, setWordsGrantedSimpleScan] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchWordsUsed = async () => {
            try {
                const e = await getWordsUsed();
                setWordsGrantedDeepScan(e.granted_words_deep_scan);
                setWordsGrantedSimpleScan(e.granted_words_simple_scan);
                setWordsUsedDeepScan(e.used_words_deep_scan);
                setWordsUsedSimpleScan(e.used_words_simple_scan);
            } catch (error) {
                console.error('Error fetching words used:', error);
            }
        };

        fetchWordsUsed();
    }, []);
    return <div className='current-plan__container'>
        <div className="current-plan__content">
            <div className="current-plan__words">
                <span className="current-plan__text">
                    Current plan
                </span>
                <span className="current-plan__words-words">
                    {formatBigNumbers(wordsGrantedSimpleScan.toString())} words
                </span>
            </div>
            <div className="progress__plan">
                <span className='words__used'>
                    <strong>{formatBigNumbers((wordsUsedSimpleScan + wordsUsedDeepScan).toString())}</strong> / {formatBigNumbers((wordsGrantedSimpleScan).toString())} words used
                </span>
                <ProgressBar progress={(wordsUsedSimpleScan + wordsUsedDeepScan)/(wordsGrantedSimpleScan)*100}/>
            </div>
        </div>
        <button className='primary' onClick={() => {
            navigate('/main/get-subscription');
            trackEvent('Get More Words');
        }}>Get More Words</button>
    </div>
}