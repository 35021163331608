import react, { useEffect, useState } from 'react'
import './style.css'
import { CheckBoxIcon,Checkmark,CrownWhite, Zipper } from '../../../../elements/icons'
import { getWordsUsed, sendSubscribeRequest } from '../../../../requests'
import { formatBigNumbers } from '../../../../elements/currentPlan'
import { Modal, Popover, Tooltip } from 'antd'
import { usePrivy } from '@privy-io/react-auth'
import { trackEvent } from '../../../../requests'

interface ICircleProgress{
    progress: number;
    wordsUsedSimple: number;
    wordsUsedDeep: number;
    deepProgress: number;
}

const PopoverContent: react.FC<{deep: boolean, wordsUsed: number}> = (props) => {
    const {deep, wordsUsed} = props;
    return <div className='popover__container'>
        <span className='popover__header'>{deep ? 'Deep scan' : 'Simple scan'}</span>
        <span className='popover__content'>
            <span className='popover__content-words'>{wordsUsed}</span> words
        </span>
    </div>
}

const CircleProgressBar: react.FC<ICircleProgress> = (props) => {
    return <svg width="250" height="250" viewBox="0 0 250 250" className='circular-progress' style={{'--progress': props.progress, '--progress-deep': props.deepProgress} as any}>
        <g transform='scale(0.224)'>
        <circle className="bg"
            cx="125" cy="125" r="115" fill="none" stroke="rgba(238, 240, 242, 1)" stroke-width="20"
        ></circle>
        <Popover content={<PopoverContent deep={false} wordsUsed={props.wordsUsedSimple}/>} color='white' overlayStyle={{whiteSpace: 'pre-line'}}>
          <circle className="fg"
              cx="125" cy="125" r="30" fill="none" stroke="rgba(158, 176, 255, 1)" stroke-width="20"
          ></circle>
        </Popover>
        <Popover content={<PopoverContent deep={true} wordsUsed={props.wordsUsedDeep}/>} color='white'>
          <circle className="deep"
              cx="125" cy="125" r="30" fill="none" stroke-width="20"
          ></circle>
        </Popover>
        </g>
  </svg>
}

const CurrentPlanContainer: react.FC = () => {
    return <div className='current__plan-widget'>Current plan</div>
}

const PopularContainer: react.FC = () => {
    return <div className='popular__container'>
        <Zipper />
        <span className='popular__cotnainer-text'>Popular</span>
    </div>
}


interface IPremiumStats{
    wordsUsedSimple: number;
    wordsUsedDeep: number;
    grantedSimple: number;
    grantedDeep: number;
    isPremium: boolean;
}
const PremiumStatisticsContainer: react.FC<IPremiumStats> = (props) => {
    const {wordsUsedSimple, wordsUsedDeep, grantedSimple, grantedDeep, isPremium} = props;
    try{
        return <div className='premium-stats__container'>
        <div className="statistics__circle">
            <div className="circle">
                <div style={{transform: 'scale(0.2)'}}></div>
                <CircleProgressBar 
                    progress={wordsUsedSimple / grantedSimple * 100} 
                    deepProgress={wordsUsedDeep / grantedDeep * 100}
                    wordsUsedSimple={wordsUsedSimple}
                    wordsUsedDeep={wordsUsedDeep}
                />
            </div>
            <div className="circle-info">
                <div className="circle-active-words premium__header">
                    Tariff package of words
                </div>
                <div className="circle-disabled-words">
                {formatBigNumbers(wordsUsedSimple.toString())}<span className='circle-disabled__words'>/{formatBigNumbers(grantedSimple.toString())} words</span>
                </div>
            </div>
        </div>
        <div className="current__plan">
            <span>Current plan</span>
            <button className={ (isPremium ? 'primary' : 'secondary free-plan-btn ') + ' current-plan-btn'}>
                {
                    isPremium ? <CrownWhite /> : <></>
                }
                <span>
                    {
                        isPremium ? 'Premium' : 'Free'
                    }
                </span>
            </button>
        </div>
    </div>
    } catch(e) {
        return <div></div>
    }
}

interface ICheckInfoText{
    text: string;
}
const CheckInfoContainer: react.FC<ICheckInfoText> = (props) => {
    const {text} = props;
    return <div className='prem-checkbox__container'>
        <CheckBoxIcon />
        <span>{text}</span>
    </div>
}


interface IPlanContainer{
    name: string;
    pricing: string;
    plans: string[];
    isPremium: boolean;
    owned: boolean;
    onClick: () => void;
}
const PlanContainer: react.FC<IPlanContainer> = (props) => {
    const {name, pricing, plans, isPremium, owned} = props;
    return <div className='plan__container'>
        <span className="container__type">
            {
                isPremium ? <PopularContainer /> : <div/>
            }
            <span>{name}</span>
            {
                isPremium ? <div style={{width: 117}}></div> : <div/>
            }
        </span>
        <span className="container__type-price">
            <span>{pricing}</span>
            {
                owned ? <CurrentPlanContainer /> : <></>
            }
        </span>
        <div className="check__containers">
            {
                plans.map(e => <CheckInfoContainer text={e}/>)
            }
        </div>
        <button 
            className={ (isPremium && !owned ? 'primary' : 'white') + ' plan__container-btn ' + (plans.length === 4 ? "mbt" : "") + ( owned ? ' disabled' : '')}
            disabled={owned}
            onClick={() => {
                props.onClick();
                trackEvent('Get Started');
            }}
        >
            {
                owned ? 'Current plan' : 'Get started'
            }
        </button>
    </div>
}


interface ISubsModal{
    open: boolean;
    setOpen: (state: boolean) => void;
}
const SubscribeModal: react.FC<ISubsModal> = (props) => {

    function transformEmail(email: string) {
        const [localPart, domain] = email.split('@');
        const maskedLocalPart = localPart.slice(0, 2) + '****';
        return `${maskedLocalPart}@${domain}`;
    }

    function validateEmail(email: string) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
    
    const [state, setState] = useState(0);
    const {user} = usePrivy();
    const [email, setEmail] = useState('');
    const [emailGetted, setEmailGetted] = useState(false);
    const [name, setName] = useState('');



    useEffect(() => {
        if (user?.email) {
            setEmail(user.email.address);
            setEmailGetted(true);
        }
    }, []);
    useEffect(() => {
        if (state === 2) {
            sendSubscribeRequest(email, name);
        }
    }, [state]);
    const states = [
        <>
            <span className="subscribe-modal__header">Subscribe</span>
            <span className="subscribe-modal__content">
                We are glad that you’ve decided to get a premium plan. Leave a request below and our sales team will connect you.
            </span>
            <button className='primary sm__request' onClick={
                () => {
                    setState(1);
                    trackEvent('Send a request');
                }}>Send a request</button>
        </>,
        <>
            <span className="subscribe-modal__header">We need a contact information</span>
            <span className="subscribe-modal__content">
            We are glad that you are interested in our service. Please leave your email below, and our team will contact you to provide more information.
            </span>
            
            {
                emailGetted ? <></> : <input className='input__info-subs' onChange={(e) => {
                    setEmail(e.target.value);
                }} placeholder='Enter your email here'/>
            }
            <input className='input__info-subs' onChange={(e) => {
                setName(e.target.value);
            }} placeholder='Enter your name here'/>
            <button className={'primary sm__request ' + (validateEmail(email) && name.length ? '' : 'disabled')} onClick={() => {
                trackEvent('Provide an information');
                if (validateEmail(email) && name.length) setState(2)
            }}>Provide an information</button>
        </>,
        <>
            <span className="subscribe-modal__header">Request sent</span>
            <span className="subscribe-modal__content">
            We will give you details about subscribtion on the e-mail linked to this account ({transformEmail(email)})
            </span>
            <button className='secondary sm__request' onClick={() => props.setOpen(false)}>OK</button>
        </>
    ]

    return <Modal
        open={props.open}
        footer={''}
        width={400}
    >
        <div className='sm__container'>
            {states[state]}
        </div>
    </Modal>
}


export const PremiumPage: react.FC = () => {
    const [grantedDeepScan, setGrantedDeepScan] = useState(-1);
    const [grantedSimpleScan, setGrantedSimpleScan] = useState(-1);
    const [wordsUsedDeepScan, setWordsUsedDeepScan] = useState(-1);
    const [wordsUsedSimpleScan, setWordsUsedSimpleScan] = useState(-1);
    const [subscription, setSubscription] = useState('');
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        getWordsUsed().then((e) => {
            setGrantedDeepScan(e.granted_words_deep_scan);
            setGrantedSimpleScan(e.granted_words_simple_scan);
            setWordsUsedDeepScan(e.used_words_deep_scan);
            setWordsUsedSimpleScan(e.used_words_simple_scan);
            setSubscription(e.subscription);
        })
    }, [])
    return <div className='premium__page__container'>
        <SubscribeModal open={modalOpen} setOpen={setModalOpen}/>
        <div className="sized">
            <div className="premium__header">Subscription</div>
            <PremiumStatisticsContainer wordsUsedSimple={wordsUsedSimpleScan + wordsUsedDeepScan} wordsUsedDeep={wordsUsedDeepScan} grantedSimple={grantedSimpleScan} grantedDeep={grantedDeepScan} isPremium={subscription !== 'Free'} />
            <div className="premium__header">Manage plans</div>
            <div className="plans">
                <PlanContainer name="Basic" pricing="Free" plans={[
                    "30k simple scan words limit",
                    "10k deep scan words limit",
                    "Extension usage"
                ]}
                isPremium={false}
                owned={subscription === 'Free'}
                onClick={() => {}}
                />
                <PlanContainer name="Premium" pricing="$10" plans={[
                    "500k simple scan words limit",
                    "500k deep scan words limit",
                    "Extension usage",
                    "ChatGPT Plugin"
                ]}
                isPremium={true}
                owned={subscription !== 'Free'}
                onClick={() => {
                    setModalOpen(true);
                }}
                />
            </div>
        </div>
    </div>
}