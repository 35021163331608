import react, { useEffect, useRef, useState } from 'react'
import { Application } from '..'
import { PrivyClient, useLogin, usePrivy } from '@privy-io/react-auth'
import { getToken, signUp } from '../../../requests';
import { APITable, APIUsage, Layout } from './layout';
import router from '../../../router'
import { Sidebar } from '../../../elements/sidebar';
import { PremiumPage } from './PremiumPage';
import {Britishizer} from "./britishizer";


export const getLogin = (user: any) => {
    if (user == null) return "  ";
    if (user.email) return user.email.address;
    if (user.wallet) return user.wallet.address;
    if (user.github) return user.github.username;
    if (user.google) return user.google.email;
    return "";
}

const getRegisteredSource = (user: any) => {
    if (user.email) return "Email";
    if (user.wallet) return "Wallet";
    if (user.github) return "Github";
    if (user.google) return "Google";
    return "Unknown";
}


export const useSystemLogin = () => {
    const {authenticated, ready, user} = usePrivy();
    const wasRequest = useRef(false);
    const {login} = useLogin({
        onComplete: (user, isNewUser, wasAlreadyAuth) => {
            var login = getLogin(user);
            if (wasAlreadyAuth) return;
            if (localStorage.getItem('token') != null) return;
            if (isNewUser) {
                console.log(user, getRegisteredSource(user));
                signUp(user.id, login, getRegisteredSource(user)).then((e) => {
                    localStorage.setItem('token', e);
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                });
            } else {
                getToken(user.id, login, getRegisteredSource(user)).then((e) => {
                    localStorage.setItem('token', e.token);
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                })
            }
        },
        onError: (e) => {
            if (e === 'exited_auth_flow' && ready) {
                setTimeout(() => {
                    login();
                }, 50);
            }
        }
    });
    useEffect(() => {
        if (!authenticated && ready && !wasRequest.current) {
            wasRequest.current = true;
            login();
        }
    }, [authenticated, login, ready, user]);

    return {ready, authenticated};
}


export const Main: react.FC = () => {
    const {ready} = useSystemLogin();
    
    var pathname = window.location.pathname.split('/');
    var lastpath = pathname[pathname.length-1];
    if (lastpath === 'main' || lastpath.length === 0) {
        window.location.replace('/main/scan')
    }
    if (lastpath === 'docs') {
        window.open('https://docs.its-ai.org/reference/text_create')
    }
    if (!ready) {
        return <></>
    }
    return <>
        <Sidebar />
        {
            lastpath === 'scan' ?
            <Layout /> :
            lastpath === 'subs' ?
            <APIUsage /> :
            lastpath === 'get-subscription' ?
            <PremiumPage /> :
            lastpath == 'britishizer' ?
            <Britishizer/> :
            <APITable />
        }
    </>
}